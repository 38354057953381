table#table-cart {
    width: 100%;

    td {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

table#cart-overview {
    td {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.btn-add-to-cart {
    cursor: pointer;
}

.fa-trash-o {
    cursor: pointer;
}

.subtotal-label {
    text-align: right;
}

.subtotal-number {
    font-weight: bold;
}

.cart-overview-container {
    background-color: #dddddd;
    padding: 10px;
}

table#cart-overview {
    width: 100%;

    tfoot {
        border-top: 2px solid #ffffff;
    }
}

.postalcode {
    text-align: right;
}

.btn-submit-cart {
    text-transform: uppercase;
}

.wpmc-tabs-wrapper {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 50px;

    .wpmc-tabs-list {
        font-weight: 600;
        background: #f9f9f9;
        margin: 0;
        padding: 0 !important;

        .wpmc-tab-item {
            font-size: 13px !important;
            line-height: 13px !important;
            text-align: center;
            text-transform: uppercase;
            position: relative;
            display: table-cell;
            float: left;
            padding-top: 0;
            padding-bottom: 47px;
            -webkit-transition: all .3s ease 0s;
            -o-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
            width: 25%;
            margin-left: 0;
            margin-right: 0;
            width: 33%;
            color: #aaa;
            display: block;
            vertical-align: top;
            padding-bottom: 20px;

            .wpmc-tab-number {
                display: inline-block;
                line-height: 28px;
                text-align: center;
                vertical-align: middle;
                width: 30px;
                min-width: 30px;
                min-height: 30px;
                height: 30px;
                margin-top: -14px;
                color: #999;
                background: #fff;
                border-radius: 50%;
                position: relative;
                -webkit-transition: color .3s,background .3s;
                -o-transition: color .3s,background .3s;
                transition: color .3s,background .3s;
                font-size: 16px;
                font-weight: 400;
                color: #000;
                background: #fff;
                border: 2px solid #ccc;
                margin-top: -14px;
            }

            .wpmc-tab-text {
                display: none;
                font-size: 13px;
                line-height: 1.25;
                font-weight: normal;
                letter-spacing: .3px;
                text-transform: capitalize;
                padding-top: 8px;
                white-space: nowrap;
                position: absolute;
                top: 15px;
                width: 100%;
                text-align: center;
                display: block;
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                font-size: 13px;
            }

            &:before {
                display: block;
                content: "";
                border-bottom: 2px solid transparent;
                height: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                -webkit-transition: border-color .3s;
                -o-transition: border-color .3s;
                transition: border-color .3s;
                border-bottom-color: #c9c9c9;
            }

            &.current {
                color: #000;

                .wpmc-tab-number {
                    border-color: #dd3333;
                }

                .wpmc-tab-text {
                    display: block;
                }

                &::before {
                    border-bottom-color: #dd3333;
                }
            }
        }
    }
}

.cart-wrap {
    color: #7b858a;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;

    .cart-title {
        margin-bottom: 13px;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -.01em;
        line-height: 32px;
        color: #222529;
    }

    .cart-price {
        color: #222529;
    }

    .cart-size-22 {
        font-size: 22px;
    }

    .cart-header {
        color: #7b858a;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
        font-weight: bold;
    }

    .font-bold {
        font-weight: bold;
    }

    .border-bt {
        border-bottom: 1px solid #e7e7e7;
    }

    .cart-empty {
        padding-top: 50px;
        padding-bottom: 50px;

        p {
            color: #2f6473;
        }
    }
}

.quality-product {
    display: flex;

    i {
        border: 1px solid #ced4da;
        width: 40px;
        text-align: center;
        vertical-align: middle;
        line-height: 40px;
    }

    input {
        width: 80px;
        border-radius: 0px;
    }

    .action {
        cursor: pointer;
    }
}

.btn-normal {
    font-weight: 400;
    text-transform: none;
    text-decoration: none !important;
}

.cart-total-badge {
    position: absolute !important;
    top: 10px !important;
    right: -2px;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    background-color: #ff5b5b;
    font-size: 10px;
    font-weight: 600;
}

.mgt-40 {
    margin-top: 40px;
}

.mgb-40 {
    margin-bottom: 40px;
}

.mgb-50 {
    margin-bottom: 50px;
}

.billing-info {
    .row {
        margin-bottom: 10px;

        label,
        h4 {
            font-weight: bold;
            font-size: 1em;
        }
    }

    .form-control {
        border-radius: 0px;
    }
}