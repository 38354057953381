.col-centered{
  margin: 0 auto;
  float: none;
}

.layout-switch-language {
  .nav-item {
    .nav-link {
      &.active {
        font-weight: bold;
      }
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}

.float-right {
  float: right;
}

.margin-left-5 {
  margin-left: 5px
}

.frontend-message {
  button {
    display: none;
  }
}

.display-flex {
  display: flex;
}

.form-repair {

  input,
  textarea {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }

  button {
    &.close {
      display: none;
    }
  }
}
.width-300 {
  width: 300px;
  height: 300px;
}
.img-who {
  width: 100%;
}
.text-center {
  text-align: center;
}
.who-is-who {
  h2 {
    margin-bottom: 2rem;
  }
}
.margin-bottom-10 {
  margin-bottom: 10px;
}

.show-inner-spin {
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.required {
  color: red;
}

#box-change-password {
  max-height: 300px;
  margin-bottom: 15px;
}

#box-category {
  .category-item {
    margin: 0 5px;
  }
  .product-favorite {
    position: absolute;
    top: -5px;
    right: 1px;
  }
}

.custom-product {
  margin: 10px 10px;
}