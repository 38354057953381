.position-fixed {
  position: fixed;
}

#footer {
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  background-color: #222529;

  .container {
    position: relative;

    .footer-ribbon {
      font-weight: 400;
      background-color: #DD3333;
      position: absolute;
      top: -58px;
      margin: -16px 0 0;
      padding: 5px 20px 6px;
      color: #fff;
      font-size: 20px;
      z-index: 101;
    }

    .footer-ribbon:before {
      border-right-color: #a81c1c;
      content: "";
      height: 0;
      position: absolute;
      top: 0;
      width: 7px;
      right: 100%;
      border-right: 10px solid #a81c1c;
      border-top: 16px solid transparent;
    }

    .box-social {
      margin-top: 50px;

      .widget {
        margin-bottom: 15px;
        margin-top: 0;

        .widget-title {
          color: #fff;
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 20px;
        }

        .text-uppercase {
          text-transform: uppercase;
        }

        .share-links {
          margin-top: 20px;
          display: flex;

          .box-fb {
            width: 35px;
            height: 35px;
            padding-top: 8px;
            padding-right: 37px;
            border-radius: 30px;
            font-size: 12px;
            margin-right: 10px;

            &:hover {
              background-color: #3b5a9a;
            }
          }

          .box-instagram {
            width: 35px;
            height: 35px;
            padding-top: 8px;
            padding-right: 37px;
            border-radius: 30px;
            font-size: 12px;
            margin-right: 10px;

            &:hover {
              background-color: #7c4a3a;
            }
          }

          .box-linkedin {
            width: 35px;
            height: 35px;
            padding-top: 8px;
            padding-right: 37px;
            border-radius: 30px;
            font-size: 12px;

            &:hover {
              background-color: #0073b2;
            }
          }

          a {
            i {
              margin: 0 15px;
              color: #fff;
            }
          }
        }
      }
    }

    .box-contact {
      .contact-info {
        ul {
          list-style-type: none;
          padding-left: 0;

          li {
            margin-bottom: 10px;

            strong {
              color: #fff;
            }

            span {
              color: grey;
              a {
                color: grey;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .hr {
      color: white;
    }

    .pull-left {
      span {
        color:#FFF;
      }
    }
  }
}