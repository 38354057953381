#main-body {
  #individual {
    margin-bottom: 3rem;
    -webkit-box-shadow: -1px -2px 4px -3px rgba(0, 0, 0, 0.75);

    .title-individual {
      font-size: 20px;
      font-weight: 700;
      margin-top: 20px;
    }

    .title-info {
      font-size: 15px;
      font-weight: 700;
    }

    .text-grey {
      color: grey;
      font-size: 14px;
    }

    strong {
      font-weight: 600;
      color: #000;
    }
  }

  #company {
    margin-bottom: 3rem;
    -webkit-box-shadow: -1px -2px 4px -3px rgba(0, 0, 0, 0.75);

    .card-body {
      .title-company {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .title-country {
        font-weight: bold;
        font-size: 14px;
      }

      .title-info {
        font-size: 14px;
        font-weight: 700;
      }

      #select-country {
        color: gray;
        background-position: right 2.75rem center;
      }

      .title-vat {
        margin: 15px 0;
        font-size: 14px;
        font-weight: 700;
      }

      #vat-error {
        display: none;
        color: #ff0000;
        font-size: 14px;
      }

      .note-vat {
        font-size: 14px;
        color: grey;
      }

      .title-account {
        font-size: 14px;
        font-weight: 700;
      }

      .box-vat {
        flex-wrap: nowrap;

        #vat-number, .input-group-text {
          border-radius: 0;
          height: 35px
        }

        .input-group-append {
          cursor: pointer
        }

        .input-group-text {
          padding: 0.415rem 3.75rem;
          color: #fff;
          background-color: #000;
          height: 36px;
        }
      }

      .btn-register {
        color: #fff;
        background-color: #000;
        border-radius: 0;
        width: 20%;
        padding: 5px;
      }

      .box-company-info, .box-account-info {
        #vat {
          color: grey;
        }

        .distance {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        .text-grey {
          color: grey;
          font-size: 14px;
        }

        strong {
          font-weight: 600;
          color: #000;
        }

      }
    }

    label {
      display: block;
      margin-bottom: 0.5rem
    }

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.3;
      color: #212529;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
    }

    .width-10 {
      width: 10%
    }

    .ml-auto {
      margin-left: auto
    }

    .progressbar {
      position: relative;
      display: flex;
      justify-content: space-between;
      counter-reset: step;
      margin: 1rem 0 2rem
    }

    .progressbar::before,
    .progress {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
      width: 100%;
      background-color: #dcdcdc;
      z-index: 1
    }

    .progress {
      width: 0%;
      transition: 0.3s
    }

    .progress-step {
      width: 2.1875rem;
      height: 2.1875rem;
      background-color: #dcdcdc;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1
    }

    .progress-step::before {
      counter-increment: step;
      content: counter(step)
    }

    .progress-step::after {
      content: attr(data-title);
      position: absolute;
      top: calc(100% + 0.5rem);
      font-size: 0.85rem;
      color: #666
    }

    .progress-step-active {
      background-color: #ff0000;
      color: #f3f3f3
    }

    .step-forms {
      display: none;
      transform-origin: top;
      animation: animate 1s
    }

    .step-forms-active {
      display: block
    }

    .group-inputs {
      margin: 1rem 0
    }

    @keyframes animate {
      from {
        transform: scale(1, 0);
        opacity: 0
      }

      to {
        transform: scale(1, 1);
        opacity: 1
      }
    }

    .btns-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
      margin-top: 15px;
    }

    .btn-next, .btn-prev {
      display: block;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      color: #fff;
      background-color: #000;
      border-radius: 0;
    }

    .btn-next {
      width: 12%
    }

    .btn-prev {
      width: 23%
    }

    //.btn:hover {
    //  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #0b4eb3;
    //  color: #fff;
    //}

    .group-inputs {
      position: relative
    }

    .group-inputs label {
      font-size: 13px;
      position: absolute;
      height: 19px;
      padding: 4px 7px;
      top: -14px;
      left: 10px;
      color: #a2a2a2;
      background-color: white
    }

    .welcome {
      height: 450px;
      width: 350px;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center
    }

    .welcome .content {
      display: flex;
      align-items: center;
      flex-direction: column
    }

    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #7ac142;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }

    .checkmark {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #fff;
      stroke-miterlimit: 10;
      margin: 10% auto;
      box-shadow: inset 0 0 0 #7ac142;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0
      }
    }

    @keyframes scale {
      0%,
      100% {
        transform: none
      }

      50% {
        transform: scale3d(1.1, 1.1, 1)
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0 0 0 30px #7ac142
      }
    }
  }
}

#selectTypeRegisterModal {
  .modal-title {
    text-transform: uppercase;
    font-size: 15px;
  }
}