#main-body {
  min-height: 310px;
  #login {
    margin-bottom: 3rem;
    -webkit-box-shadow: -1px -2px 4px -3px rgba(0, 0, 0, 0.75);

    .box-login, .box-register {
      h4 {
        text-transform: uppercase;
        font-size: 15px;
        margin-bottom: 20px;
      }

      .text-forgot {
        color: #000 !important;
        text-decoration: none;
      }
    }
  }
}

.btn-login, .btn-register-individual, .btn-register-company {
  color: #fff;
  background-color: #000;
  border-radius: 0;
  padding: 5px 15px;
  font-size: 15px;
  &:hover {
    color: #fff;
  }
}

