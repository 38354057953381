body {
    .breadcrumbs-wrap {
        color: #8e8e8e;
        font-size: .75rem;

    .breadcrumb {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      list-style: none;

      li {
        position: relative;
        text-shadow: none;
        text-transform: uppercase;

        a {
          display: inline-block;
          border-color: transparent;
          background: 0 0;
          color: #8e8e8e;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        i {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }
}