.btn-password-recovery {
  color: #fff;
  background-color: #000;
  border-radius: 0;
  padding: 5px 15px;
  font-size: 15px;
  &:hover {
    color: #fff;
  }
}

